// @flow
import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'

import logo from '../../assets/optique-logo.svg'

const ALogo = ({ alt, ...props }) => (
  <StaticQuery
    query={graphql`
      query MetaData {
        site {
          siteMetadata {
            title
            language
          }
        }
      }
    `}
    render={staticData => (
      <img
        src={logo}
        alt={staticData.site.siteMetadata.title}
        width="204"
        height="76"
        {...props}
      />
    )}
  />
)

// ALogo.propTypes = {
//   alt: PropTypes.string,
// }
//
// ALogo.defaultProps = {
//   alt: '',
// }

export default ALogo
