import React from 'react'
import styles from './ABanner.module.scss'

import { Link } from 'gatsby'

const classNames = require('classnames')

const ABanner = ({ children }) => (
  <div className={styles.banner}>{children}</div>
)

export default ABanner
