import PropTypes from 'prop-types'
import React from 'react'

import { Link } from 'gatsby'

import styles from './OFooter.module.scss'

const OFooter = ({ children, ...props }) => (
  <footer className={styles.footer} {...props}>
    {children}
    <ul className={styles.nav}>
      <li className={styles.navItem}>
        <Link className={styles.navLink} to="/impressum/">
          Impressum
        </Link>
      </li>
    </ul>
  </footer>
)

OFooter.propTypes = {
  children: PropTypes.node.isRequired,
}

export default OFooter
