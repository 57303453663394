import PropTypes from 'prop-types'
import React from 'react'
import styles from './ASection.module.scss'

const classNames = require('classnames')

const ASection = ({ children, className, type, ...props }) => {
  const classes = classNames(
    {
      [styles.section]: true,
      [styles.primary]: type === 'primary' || type === '',
      [styles.secondary]: type === 'secondary',
    },
    className
  )

  return (
    <section className={classes} {...props}>
      {children}
    </section>
  )
}
ASection.propTypes = {
  children: PropTypes.oneOf([PropTypes.node, PropTypes.boolean]),
  className: PropTypes.oneOf([PropTypes.Object, PropTypes.boolean]),
  type: PropTypes.string,
}

ASection.defaultProps = {
  children: PropTypes.node,
  className: false,
  type: 'primary',
}

export default ASection
