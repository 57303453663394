import PropTypes from 'prop-types'
import React from 'react'

import styles from './AType.module.scss'

const classNames = require('classnames')

const AType = ({ align, children, className, type, ...props }) => {
  const CustomTag = `${type}`
  const classes = classNames({
    [styles[className]]: className.length,
    'text-center': align === 'center',
  })

  return (
    <CustomTag className={classes.length ? classes : false} {...props}>
      {children}
    </CustomTag>
  )
}

AType.propTypes = {
  align: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
}

AType.defaultProps = {
  align: '',
  type: 'p',
  className: '',
}

export default AType
