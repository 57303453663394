import React from 'react'

import { Link } from 'gatsby'

import styles from './AOpeningHours.module.scss'

const AOpeningHours = () => (
  <div className={styles.item}>
    <p>
      Mo: 12:00 Uhr bis 18:00 Uhr
      <br />
      Di: <Link to="/termin/">nach Vereinbarung</Link><br />
      Mi & Do: 10:00 Uhr bis 19:00 Uhr
      <br />
      Fr: 12:00 Uhr bis 18:00 Uhr
      <br />
      Sa: 10:00 Uhr bis 15:00 Uhr
    </p>
    <p>
      Außerhalb der Öffnungszeiten gerne mit <Link to="/termin/">Termin via Onlineplaner</Link>
    </p>
  </div>
)

export default AOpeningHours
