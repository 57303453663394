// @flow
import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as IconFacebook } from 'simple-icons/icons/facebook.svg'
import { ReactComponent as IconInstagram } from 'simple-icons/icons/instagram.svg'

import styles from './ASocialIcons.module.scss'

const icons = {
  facebook: IconFacebook,
  instagram: IconInstagram,
}

const ASocialIcons = ({ links, ...props }) => {
  const listItems =
    typeof links === 'object' && links.length
      ? links.map((link, index) => {
          const Icon = icons[link.network.toLowerCase()]

          return typeof link.url === 'string' && link.url ? (
            <li key={index} className={styles.item}>
              <a
                href={link.url}
                className={styles.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon className={styles.icon} />
              </a>
            </li>
          ) : (
            false
          )
        })
      : 'Please define your array of menu iconListEntries'

  // console.log('listItems', listItems)
  return <ul className={styles.root}>{listItems}</ul>
}

ASocialIcons.propTypes = {
  links: PropTypes.array,
}

ASocialIcons.defaultProps = {
  links: {},
}

export default ASocialIcons
