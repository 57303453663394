import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
// import withSizes from 'react-sizes'

import ALogo from '../01-atoms/ALogo'
import AMenuButton from '../01-atoms/AMenuButton'
import ASocialIcons from '../01-atoms/ASocialIcons'

import styles from './ONavBar.module.scss'

const classNames = require('classnames')

class ONavBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = { opened: false }

    // This binding is necessary to make `this` work in the callback
    this.handleToggle = this.handleToggle.bind(this)
  }

  handleToggle() {
    this.setState(prevState => ({
      opened: !prevState.opened,
    }))
  }

  render() {
    const { title, ...props } = this.props

    const navClasses = classNames({
      [styles.nav]: true,
      [styles.navIsOpen]: this.state.opened,
    })

    return (
      <nav className={styles.root} {...props}>
        <div className={styles.title}>
          <Link to="/" className={styles.titleLink}>
            <ALogo className={styles.logo} alt={title} />
          </Link>
        </div>

        <ul className={navClasses}>
          <li className={styles.navItem}>
            <Link to="/" className={styles.navItemLink}>
              <span className={styles.navItemLinkLabel}>Home</span>
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link to="/leistungen/" className={styles.navItemLink}>
              <span className={styles.navItemLinkLabel}>Leistungen</span>
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link to="/team/" className={styles.navItemLink}>
              <span className={styles.navItemLinkLabel}>Team & Aktuelles</span>
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link to="/marken/" className={styles.navItemLink}>
              <span className={styles.navItemLinkLabel}>Marken</span>
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link to="/termin/" className={styles.navItemLink}>
              <span className={styles.navItemLinkLabel}>Termin</span>
            </Link>
          </li>
          <li>
            <ASocialIcons
              links={[
                {
                  url: 'https://www.instagram.com/optique_stuttgart/',
                  network: 'Instagram',
                },
                {
                  url: 'https://www.facebook.com/optiquestuttgart/',
                  network: 'Facebook',
                },
              ]}
            />
          </li>
        </ul>

        <AMenuButton toggled={this.state.opened} onClick={this.handleToggle} />
      </nav>
    )
  }
}

ONavBar.propTypes = {
  title: PropTypes.string.isRequired,
  // belowBreakpoint: PropTypes.bool.isRequired,
}

// const mapSizesToProps = ({ width }) => ({
//   belowBreakpoint: width < 900,
// })
//
// export default withSizes(mapSizesToProps)(ONavBar)

export default ONavBar
