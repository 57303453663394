// @flow
import PropTypes from 'prop-types'
import React from 'react'
import styles from './AButtonMenu.module.scss'

const classNames = require('classnames')

const AMenuButton = ({ toggled, ...props }) => (
  <button
    className={classNames({
      [styles.hamburger]: true,
      [styles.hamburgerArrowR]: true,
      [styles.isActive]: toggled,
      [styles.button]: true,
    })}
    {...props}
    type="button"
    aria-label="Menu"
    aria-controls="navigation"
    aria-expanded={toggled}
  >
    <span className={styles.hamburgerBox}>
      <span className={styles.hamburgerInner} />
    </span>
  </button>
)

AMenuButton.propTypes = {
  toggled: PropTypes.bool,
}

AMenuButton.defaultProps = {
  toggled: false,
}

export default AMenuButton
