import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

import { graphql, Link, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import ABanner from '../components/01-atoms/ABanner'
import AType from '../components/01-atoms/AType'

import MSectionContact from '../components/02-molecules/MSectionContact'

import ONavBar from './03-organisms/ONavBar'
import OFooter from './03-organisms/OFooter'

import styles from './Layout.module.scss'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
            language
          }
        }
        plant1: file(relativePath: { eq: "plant1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, excludeOriginal: true, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        plant2: file(relativePath: { eq: "plant2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, excludeOriginal: true, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={staticData => (
      <React.Fragment>
        <header className={styles.header}>
          <Helmet
            titleTemplate={`%s | ${staticData.site.siteMetadata.title}`}
            defaultTitle={staticData.site.siteMetadata.title}
          >
            <html lang={staticData.site.siteMetadata.language} />
          </Helmet>

          <ONavBar title={staticData.site.siteMetadata.title} />
        </header>

        <main>
          {/* <ABanner>
            <AType>
              <span style={{ textTransform: 'uppercase' }}>
                Neue Öffnungszeiten:
              </span>{' '}
              Dienstags nur <Link to="/termin/">nach Vereinbarung</Link> und
              Samstags bis 15:00 Uhr.
            </AType>
          </ABanner> */}

          <Img
            fluid={staticData.plant1.childImageSharp.fluid}
            backgroundColor="#ffffff"
            style={{
              position: 'fixed',
              width: 600,
              'max-width': '47%',
              left: '0',
              top: '5%',
              'z-index': '0',
              transform: 'translateX(-40%) translateY(5%)',
            }}
            width={600}
            height={430}
          />
          <Img
            fluid={staticData.plant2.childImageSharp.fluid}
            backgroundColor="#ffffff"
            style={{
              position: 'fixed',
              width: 600,
              'max-width': '47%',
              right: '0',
              bottom: '0',
              'z-index': '0',
              transform: 'translateX(35%) translateY(40%)',
            }}
            width={600}
            height={591}
            alt=""
          />
          {children}
        </main>

        <MSectionContact />
        <OFooter />
      </React.Fragment>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
