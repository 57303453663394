import React from 'react'

import { Link } from 'gatsby'

import ALogo from '../01-atoms/ALogo'
import AOpeningHours from '../01-atoms/AOpeningHours'
import ASection from '../01-atoms/ASection'

import styles from './MSectionContact.module.scss'

const MSectionContact = () => (
  <ASection className={styles.section} type="secondary">
    <Link to="/" className={styles.logoLink}>
      <ALogo className={styles.logo} />
    </Link>

    <AOpeningHours />
    <div className={styles.item}>
      <p>
        Hauptstätterstraße 136
        <br />
        Eingang Römerstraße
        <br />
        70178 Stuttgart
      </p>
      <p>
        <a href="tel:+4971190123344" className={styles.link}>
          07 11-90 12 33 44
        </a>
        <br />
        <a href="mailto:info@optique-stuttgart.de" className={styles.link}>
          info@optique-stuttgart.de
        </a>
      </p>
    </div>
  </ASection>
)

export default MSectionContact
